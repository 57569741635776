<template>
  <no-sidebar-layout>
    <v-container class="px-0 pb-6">
      <v-row class="d-flex flex-column justify-center  my-0">
        <v-img src="@/assets/password.svg" max-width="200px" max-height="200px" contain class="mt-4 mb-2 rounded-circle align-self-center" />
      </v-row>
      <v-form class="form" @submit.prevent="setPassword">
        <h2 class="text-center mt-8" v-text="$t('setPassword.subtitle')" />
        <p class=" mt-4 body-2 font-weight-light text-center" v-text="$t('setPassword.description')" />
        <v-text-field
          ref="password"
          v-model="form.password" :label="$t('user.fields.password')"
          :type="showPassword ? 'text' : 'password'" name="password"
          :error-messages="getFieldErrors('password')"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @blur="$v.form.password.$touch()"
        />
        <v-btn
          type="submit"
          class="mt-4 mb-4"
          color="primary"
          block
          depressed
          v-text="$t('setPassword.button')"
        />
      </v-form>
    </v-container>
  </no-sidebar-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'SetPassword',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
  },
  mixins: [formUtils],
  data() {
    return {
      logo: '',
      club: null,
      showPassword: false,
      form: {
        password: null,
      },
    }
  },
  computed: {
    oobCode() {
      return this.$route.query.oobCode
    },
    email() {
      return this.$route.query.email
    },
  },
  validations: {
    form: {
      password: { required },
    },
  },

  methods: {
    async setPassword() {
      if (!this.isFormValid()) return
      try {
        await this.$store.dispatch('user/setPassword', { oobCode: this.oobCode, password: this.form.password })
        this.$router.push({ name: 'status', params: { type: 'success', title: 'user.passwordResetSuccess' } })
      } catch (error) {
        const errorText = this.$t(`setPassword.errorCodes.${error.code}`)
        this.$router.push({ name: 'setPasswordError', params: { errorText, email: this.email } })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  max-width: 320px;
  min-width: 320px;
  margin: auto;
}

</style>
